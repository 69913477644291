:root {
    --col-prim: hsl(170, 100%, 37%);
    --col-prim-glass: hsla(170, 100%, 37%, 70%);

    --col-prim-gradient-beg: #0faca8;
    --col-prim-gradient-end: #1fb398;

    --col-prim-elem: hsl(170, 100%, 34%);
    --col-prim-elem-hover: hsl(168, 100%, 40%);
    --col-prim-elem-active: hsl(168, 100%, 30%);
    --col-prim-elem-inactive: hsla(170, 100%, 34%, 20%);

    --col-attention: hsl(350, 90%, 50%);
    --col-attention-elem: hsl(350, 80%, 40%);
    --col-attention-elem-hover: hsl(350, 85%, 48%);
    --col-attention-elem-active: hsl(350, 83%, 30%);
    --col-attention-elem-inactive: hsla(350, 80%, 30%, 20%);

    /* Background colors convey structure
     * dp0: Absolut html basis
     * dp1: Page background
     * dp2: Page overlays (sidebar, etc)
     * dp3: Header
     * dp4: Popups, widgets, overlays
     */
    --col-bg-dp0: hsl(240, 21%, 7%);
    --col-bg-dp1: var(--col-bg-dp0);
    --col-bg-dp2: hsla(245, 19%, 14%, 70%);
    --col-bg-dp3: var(--col-bg-dp2);
    --col-bg-dp4: 0;

    --col-bg-elem-mod-hover: #99d1;     /* Applied as a modifier over base background color */
    --col-bg-elem-mod-active: #0012;    /* Applied as a modifier over base background color */
    --col-bg-elem-mod-inactive: #0000003f;  /* Applied as a modifier over base background color */

    --col-elem-separator-border: hsl(245, 15%, 16%);
    --col-elem-socket-border: hsl(245, 10%, 40%);
    --col-elem-socket-border-inactive: hsla(245, 10%, 40%, 40%);
    --col-elem-bg-socket: hsl(245, 20%, 12%); /* For elements that accept insertion of some form (text fields, checkboxes, etc) */
    --col-elem-bg-socket-hover: hsl(245, 25%, 20%);
    --col-elem-bg-socket-active: hsl(245, 20%, 8%);
    --col-elem-bg-socket-inactive: hsla(245, 20%, 12%, 20%);

    /* Foreground colors convey information
     * text:    Colors intended for text
     * icon:    Colors intended for icons
     * handle:  Colors intended for element handles (scrollbars, help buttons, close buttons)
     */
     --col-fg-text: hsl(0, 0%, 92%);
     --col-fg-text-highlight: hsl(0, 0%, 100%);
     --col-fg-text-hint:hsl(0, 0%, 77%);
     --col-fg-text-inactive: hsl(0, 0%, 50%);

     --col-fg-icon: hsl(0, 0%, 92%);
     --col-fg-icon-highlight: hsl(0, 0%, 100%);
     --col-fg-icon-hint:hsl(0, 0%, 66%);
     --col-fg-icon-inactive: hsl(0, 0%, 33%);

     --col-fg-handle: hsl(245, 20%, 80%);
     --col-fg-handle-hover: hsl(245, 20%, 90%);
     --col-fg-handle-active: hsl(245, 20%, 70%);
     --col-fg-handle-inactive: hsl(245, 20%, 70%, 50%);
}