@import '../../../styles/theme.css';

.loading-spinner {
    position: relative;
}

.loading-spinner div {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-style: solid;
    animation: 1.6s ease-in-out infinite loading-spinner-rotate-anim;
}

.loading-spinner div:nth-child(2) {   
    animation-delay: -0.4s;
}

@keyframes loading-spinner-rotate-anim {
      0% { rotate: 0deg; }
    100% { rotate: 360deg; }
}
