@import '../../assets/styles/theme.css';

.scene-button {
    box-sizing: border-box;
    padding: 8px;
    user-select: none;
    cursor: pointer;
    background-color: var(--color-bg-elem-dp2-dk);
    border-radius: 12px;
}

.scene-button:hover {
    background-color: var(--color-bg-elem-dp2);
}

.scene-button:active {
    background-color: var(--color-bg-elem-dp1);
}

.scene-elements {
    margin-left: 4px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.scene-element-tag {
    font: var(--font-body-small);
    color: var(--color-foreground-hinted);
    background-color: var(--color-bg-elem-dp1-dk);
    padding: 0px 8px;
    border-radius: 16px;
    max-width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scene-button-remove:hover {
    color: var(--color-danger);
}