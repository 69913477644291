@import '../../assets/styles/theme.css';

.atmosphere-card {
    position: relative;
    width: 300px;
    height: 300px;
    min-width: 300px;
    min-height: 300px;
    border-radius: 24px;
    background-color: var(--color-bg-elem-dp1-dk);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.3s;
    box-shadow: 2px 2px 2px var(--color-bg-elem-dp1-glass);
    cursor: pointer;
}

.atmosphere-card-overlay {
    position: absolute;
    box-sizing: border-box;
    width: 300px;
    height: 80%;
    top: -80%;
    padding: 16px;
    background-color: var(--color-bg-elem-dp1-dk-glass80);
    transition: 0.3s;
}

.atmosphere-card:hover {
    box-shadow: 4px 4px 2px var(--color-bg-elem-dp1-lg-glass);
}

.atmosphere-card:hover .atmosphere-card-overlay {
    top: 0;
}

.atmosphere-card-overlay .detail-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-right: 4px;
}

.detail-container .creator {
    font: var(--font-body-small);
    color: var(--color-foreground-hinted);
}

.detail-container .tag-container {
    margin: 6px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.detail-container .description {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.atmosphere-card .atmosphere-card-thumbnail {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.atmosphere-card-short-desc {
    box-sizing: border-box;
    height: 20%;
    padding: 4px 8px;
    display: flex;
}

.atmosphere-card-short-desc .info-container {
    width: 90%;   
}

.atmosphere-card-short-desc .title {
    font: var(--font-h4-headline);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.atmosphere-card-short-desc .creator {
    font: var(--font-body-small);
    color: var(--color-foreground-hinted);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.atmosphere-card-short-desc .signature-container {
    width: 10%;
}

.atmosphere-card-short-desc .signature {
    font: var(--font-body-small);
    color: var(--color-foreground-hinted);
    display: flex;
    justify-content: flex-end;
}

.atmosphere-card-official-logo {
    width: 24px;
    padding: 2px 0px;
}