@import '../../../styles/theme.css';

.checkbox {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    position: relative;
    text-align: center;    
    min-width: var(--fs-elem-lg);
    min-height: var(--fs-elem-lg);
    width: var(--fs-elem-lg);
    height: var(--fs-elem-lg);
    border-radius: var(--lay-border-radius-tn);
    border: var(--lay-border-slim) solid var(--col-elem-socket-border);
    margin: calc((var(--lh-mult-elem) - 1) * var(--fs-elem-lg)) 0px;
    cursor: var(--ia-cursor-clickable);
    background-color: var(--col-elem-bg-socket);
    align-self: flex-start;
    transition: var(--ia-transition-instant);
}

.checkbox.invalid {
    border: var(--lay-border-slim) solid var(--col-attention);
}

.checkbox.checked.invalid,
.checkbox.checked.invalid:hover,
.checkbox.checked.invalid:active {
    border: var(--lay-border-thick) solid var(--col-attention);
}

.checkbox:hover {
    background-color: var(--col-elem-bg-socket-hover);
}

.checkbox:active {
    background-color: var(--col-elem-bg-socket-active);
}

.checkbox.checked {
    color: var(--col-fg-text);
    background-color: var(--col-prim-elem);
    border: var(--lay-border-slim) solid var(--col-prim-elem);
}

.checkbox.checked:hover {
    color: var(--col-fg-text-highlight);
    background-color: var(--col-prim-elem-hover);
    border: var(--lay-border-slim) solid var(--col-prim-elem-hover);
}

.checkbox.checked:active {
    background-color: var(--col-prim-elem-active);
    border: var(--lay-border-slim) solid var(--col-prim-elem-active);
}

.checkbox.checked::before {
    content: "\2713";
    font-size: calc(var(--fs-elem-sm) - var(--lay-border-slim));
    position: absolute;
    inset: 0;
    top: calc(-1 * var(--lay-border-slim) + 0.5 * (var(--fs-elem-lg) - var(--fs-elem-sm)));
}

.checkbox-label {
    font: var(--font-body);
    margin-left: var(--lay-gap-h-connect);
    cursor: var(--ia-cursor-clickable);
}

.checkbox-invalid-label {
    font: var(--font-body-sm);
    line-height: 1;
    margin-left: calc(var(--fs-body) + var(--lay-gap-h-connect));
    color: var(--col-attention);
}

.checkbox.deactivated {
    border-color: var(--col-elem-socket-border-inactive);
    background-color: var(--col-elem-bg-socket-inactive);
}

.checkbox.checked.deactivated,
.checkbox.checked.deactivated:hover {
    cursor: default;
    color: var(--col-fg-text-inactive);
    background-color: var(--col-prim-elem-inactive);
    border-color: var(--col-prim-elem-inactive);
}

.checkbox-label.deactivated {
    cursor: default;
    color: var(--col-fg-text-inactive);
}