@import "../../../styles/theme.css";

.footer {
    width: 100%;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    font: var(--font-elem-sm);
}

.footer > .legal-links {
    max-width: var(2 * --lay-column-width);
    box-sizing: border-box;
    padding: var(--lay-pad-compact);
    
    display: flex;
    flex-direction: column;
}