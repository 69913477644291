@import '../../assets/styles/theme.css';

.tag {
    box-sizing: border-box;
    height: 18px;
    padding: 0px 8px;
    border-radius: 9px;
    font: var(--font-body-small);
    color: var(--color-foreground);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.xl-tag {
    box-sizing: border-box;
    height: 22px;
    padding: 0px 8px;
    border-radius: 11px;
    font: var(--font-body-medium);
    color: var(--color-foreground);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}