@import "../../assets/styles/theme.css";

.depr-footer{
    width: 100%;
    height: var(--size-footer-height);
    padding: 8px 0px 6px 12px;
    box-sizing: border-box;
    font: var(--font-body-small);
    background: linear-gradient(0deg, #0003, transparent);
}

.depr-footer.stacked {
    padding: 0;
    background: transparent;
}

.depr-footer.stacked .depr-head {
    background: linear-gradient(0deg, #0003, transparent);
    padding: 0px 4px 0px 8px;
}

.depr-footer.stacked .depr-body {
    background-color: #0003;
    padding: 0px 4px 4px 8px;
}

.depr-footer-link-list{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.depr-footer-link-list a{
    color: var(--color-foreground);
}

.depr-footer.stacked .depr-collapsable-link-list-header{
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    color: var(--color-foreground-hinted)
}

.depr-collapsable-link-list-header .depr-collapse-button {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
}

.depr-collapsable-link-list-header:hover .depr-collapse-button {
    background-color: var(--color-bg-elem-dp2-lg)
}

.depr-collapsable-link-list-header:active .depr-collapse-button {
    background-color: var(--color-primary)
}

.depr-collapsable-link-list {
    padding: 4px 4px 4px 24px;
    transition: 0.5s;
    overflow: hidden;
    opacity: 1;
}

.depr-collapsable-link-list.collapsed {
    height: 0px;
    opacity: 0;
}

.depr-collapsable-link-list.stacked {
    height: 64px;
}

.depr-collapsable-link-list .footer-link-list {
    flex-direction: column;
    gap: 4px;
}