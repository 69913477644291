@import './colors.css';
@import './fonts.css';
@import './interact.css';
@import './layout.css';

/* Image placeholder background color */

/* General Layout */
.page-fixed {
    position: absolute;
    inset: 0;
}

.page-v-scroll {
    position: absolute;
    inset: 0;
    width: 100%;
    overflow: auto;
}

/* Header */
h1 {
    margin: 0;
    font: var(--font-head-h1);
    user-select: var(--ia-user-select-head);
}

h2 {
    margin: 0;
    font: var(--font-head-h2);
    user-select: var(--ia-user-select-head);
}

h3 {
    margin: 0;
    font: var(--font-head-h3);
    user-select: var(--ia-user-select-head);
}

h4 {
    margin: 0;
    font: var(--font-head-h4);
    user-select: var(--ia-user-select-head);
}

/* Links */
a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-weight: bold;
    color: var(--col-fg-text-highlight);
    cursor: var(--ia-cursor-clickable);
}

a:hover {
    text-decoration: underline;
}

/* Text */
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Scrollbar */
.custom-scrollbar,
.custom-scrollbar-horizontal {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal::-webkit-scrollbar-thumb  {
    background-color: transparent;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.01);
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar-horizontal::-webkit-scrollbar {
    height: 8px;
}

.custom-scrollbar:hover,
.custom-scrollbar:focus,
.custom-scrollbar:focus-within,
.custom-scrollbar-horizontal:hover,
.custom-scrollbar-horizontal:focus,
.custom-scrollbar-horizontal:focus-within {
    scrollbar-color: var(--col-fg-handle) rgba(0,0,0,0.25);
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:focus::-webkit-scrollbar-thumb,
.custom-scrollbar:focus-within::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal:hover::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal:focus::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal:focus-within::-webkit-scrollbar-thumb {
    background-color: var(--col-fg-handle);
}

.custom-scrollbar-always,
.custom-scrollbar-always-horizontal {
    scrollbar-width: thin;
    scrollbar-color: var(--col-fg-handle) rgba(0,0,0,0.25);
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
}

.custom-scrollbar-always::-webkit-scrollbar-thumb,
.custom-scrollbar-always-horizontal::-webkit-scrollbar-thumb  {
    background-color: var(--col-fg-handle);
}

.custom-scrollbar-always::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar-always-horizontal::-webkit-scrollbar {
    height: 8px;
}