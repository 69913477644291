@import '../../assets/styles/theme.css';

.header {
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(var(--color-bg-elem-dp1), 90%, var(--color-bg-elem-dp1-dk));
    border-bottom: 2px solid var(--color-bg-elem-dp1-dk-glass);
    display: flex;
    flex-direction: row;
}
