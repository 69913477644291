@import '../../../styles/theme.css';

.signup-page-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-form-background {
    display: flex;
    flex-direction: column;
    width: var(--lay-column-width);
    gap: var(--lay-gap-h-separate);
    border-radius: 20px;
    padding: 16px;
}

.signup-form {
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px;
    gap: var(--lay-gap-h-connect);
}

.signup-form-help{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--lay-gap-h-connect);
    flex-wrap: wrap;
}

.signup-form-separator{
    width: 100%; 
    height: 1px;
    background-color: var(--col-elem-socket-border);
    margin-top: var(--lay-space-h-short);
}

.signup-button-loading{
    display: flex;
    flex-direction: column;
    align-items: center;
}