@import '../../../assets/styles/theme.css';

.email-verification-page-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-verification-widget {
    max-width: 460px;
    margin: 16px;
    
    border-radius: 20px;
    background-color: var(--color-bg-elem-dp1-glass);
    box-shadow: 2px 2px 2px var(--color-bg-elem-dp1-dk-glass);
    padding: 16px;
}

.email-verification-widget.vertical {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.email-verification-widget.horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.email-verification-email {
    color: var(--color-foreground-hinted);
}