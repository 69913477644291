@import '../../../../assets/styles/theme.css';

.hub-subpage-library {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
}

.hub-subpage-library .info-box {
    display: flex;
}

.hub-subpage-library .info-box .info-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.hub-subpage-library .info-box .info-desc {
    display: flex;
    flex-direction: column;
}

.hub-subpage-library .atm-container {
    margin: 12px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.hub-subpage-library h3 {
    margin: 16px 0px 8px 0px;
}