@import '../../../assets/styles/theme.css';
@import '../../../../styles/theme.css';

.web-player-page-container {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.web-player-close-button {
    color: var(--color-foreground);
    font-weight: bold;
    cursor: pointer;
}

.web-player-close-button:hover {
    text-decoration: underline;
}

.web-player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
}

.web-player .sidebar-area {
    position: relative;
    width: 348px;
    height: 100%;
    transition: 0.5s;
}

.web-player .sidebar-area.retracted {
    width: 49px;
}

.web-player .sidebar-stack {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 4px 0px;
    border-left: solid 1px var(--color-bg-elem-dp1);
    background-color: var(--color-bg-elem-dp1-dk);
    display: flex;
    flex-direction: column;
}

.web-player .side-menu-button {
    width: 48px;
    height: 48px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: var(--font-elem-tn);
    transition: 0.1s;
    user-select: none;
    cursor: pointer;
    border-radius: 4px;
}

.web-player .side-menu-button.active {
    background-color: var(--color-bg-elem-dp1-lg);
}

.web-player .side-menu-button:hover {
    background-color: var(--color-bg-elem-dp1-lg);
}

.web-player .side-menu-button:hover.active {
    background-color: var(--color-bg-elem-dp2-lg);
}

.web-player .side-menu-button:active {
    background-color: var(--color-bg-elem-dp1-dk);
}

.web-player .sidebar-area .sidebar {
    position: absolute;
    padding: 8px 60px 8px 12px;
    box-sizing: border-box;
    width: 348px;
    height: 100%;
    right: 0px;
    transition: 0.5s;
    background-color: var(--color-bg-elem-dp1-dk-glass);
}

.web-player .sidebar-area.retracted .sidebar {
    right: -300px;
}

.web-player .sidemenu-transition-fix {
    display: none;
}

.web-player .atmosphere-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

@media (max-width: 760px) {
    .web-player .sidebar-area {
        width: 100%;
    }

    .web-player .sidebar-area .sidebar {
        width: 100%;
    }

    .web-player .sidemenu-transition-fix.non-exclusive {
        display: block;
        flex-grow: 1;
    }

    .web-player .atmosphere-container.non-exclusive {
        display: none;
    }
}

.web-player .atmosphere-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.web-player-splash-container .atmosphere-title-container {
    display: flex;
    align-items: center;
}

.web-player .atmosphere-title-thumb,
.web-player-splash-container .atmosphere-title-thumb {
    border-radius: 4px;
    width: 64px;
    height: 64px;
    object-fit: cover;
}

.web-player .atmosphere-title-info,
.web-player-splash-container .atmosphere-title-info {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.web-player .atmosphere-matrix-container {
    position: relative;
    height: 100%;
    margin: 24px 64px;
}

.web-player .mockup-info {
    font: var(--font-body-medium);
    color: var(--color-foreground-hinted);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}

.web-player-splash-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-background-glass);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.web-player-splash-widget {
    border-radius: 24px;
    padding: 16px 24px;
    background-color: var(--color-bg-elem-dp2);
    box-shadow: 2px 2px 2px var(--color-bg-elem-dp2-dk-glass);
    min-width: 360px;
    max-width: 480px;
    max-height: 480px;
    display: flex;
    flex-direction: column;
}

.web-player-splash-container .info-container {
    margin: 16px 0px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
}

.web-player-splash-container .tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.web-player-splash-container .text {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.web-player .side-menu-content {
    width: 100%;
    height: 100%;
}

.web-player .side-menu-content .info-text {
    font: var(--font-body-medium);
    color: var(--color-foreground-hinted);
    display: flex;
}

.web-player .volume-control {
    margin: 8px;
    user-select: none;
}

.web-player .playback-meta {
    margin: 8px 0px;
}

.web-player .custom-scene-creator {
    position: absolute;
    z-index: 60;

    width: 100%;
    top: -8px;
    right: calc(100% + 8px);
    box-sizing: border-box;
    margin: 2px 0px;
    padding: 8px;
    border: solid medium var(--color-bg-elem-dp1-dk);
    border-radius: 8px;
    background-color: var(--color-bg-elem-dp2-dk);

    opacity: 1;
    transition: 0.3s;
}

.web-player .custom-scene-creator.closed {
    opacity: 0;
    pointer-events: none;
}

.web-player .custom-scene-creator-close-btn {
    color: var(--color-foreground-hinted);
}

.web-player .custom-scene-creator-close-btn:hover {
    color: var(--color-foreground);
}

.web-player .custom-scene-creator-tag-select-btn {
    color: var(--color-foreground-inactive);
    height: 100%;
    display: flex;
    align-items: center;
}

.web-player .custom-scene-creator-tag-select-btn:hover {
    color: var(--color-primary);
}

.web-player .custom-scene-creator-tag-select-btn.selected:hover {
    color: var(--color-danger);
}