@import '../../../../assets/styles/theme.css';

.confirmation-banner-overlay {
    position: absolute;
    inset: 0;
    background-color: #0007;
    display: flex;
    align-items: center;
}

.confirmation-banner {
    width: 100%;
    max-width: 360px;
    padding: 8px;
    background: var(--color-bg-elem-dp1);
    border-radius: 16px;
    box-shadow: 2px 2px var(--color-bg-elem-dp1-dk-glass);
}