@import '../../../styles/theme.css';

.simple-tab-register-head {
    padding: var(--lay-pad-slim);
    font: var(--font-elem-md);
    user-select: none;
    cursor: var(--ia-cursor-clickable);
    border-bottom: solid var(--lay-border-slim) var(--col-elem-separator-border);
    background: linear-gradient(#0000, #0005);
    transition: var(--ia-transition-instant);
}

.simple-tab-register-head:hover{
    background-color: var(--col-bg-elem-mod-hover);
}

.simple-tab-register-head:active{
    background-color: var(--col-bg-elem-mod-active);
}

.simple-tab-register-head.active {
    background: transparent;
    cursor: default;
    border-bottom: solid var(--lay-border-slim) transparent;
}

.simple-tab-register-header {
    display: grid;
}