:root {
    --ia-transition-instant: 0.1s; /* A subtle transition that feels smooth but instant */
    --ia-transition-vis-fast: 0.3s; /* A visible transition that feels fast */
    --ia-transition-vis-slow: 0.5s; /* A visible transition that feels slower, but still fast enough */

    --ia-user-select-head: auto;
    --ia-user-select-elem: none;
    --ia-user-select-body: auto;
    
    --ia-cursor-help: help;
    --ia-cursor-loading: progress;
    --ia-cursor-clickable: pointer;
    --ia-cursor-text-edit: text;
}