@import '../../../assets/styles/theme.css';

.maintenance-disclaimer-page-container {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.maintenance-disclaimer {
    max-width: 480px;
    margin: 16px;
    padding: 16px;
    border-radius: 16px;
    background-color: var(--color-bg-elem-dp1-dk-glass);
}

.maintenance-disclaimer p {
    margin: 0px;
}