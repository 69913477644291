@import '../../../assets/styles/theme.css';

.survey-page-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.survey-page {
    position: relative;
    flex-grow: 1;
    background-color: var(--color-bg-elem-dp1-dk-glass);
    max-width: 720px;
    width: 90%;
    margin: 24px auto;
    border-radius: 16px;
}

.survey-message-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}