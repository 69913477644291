@import '../../../styles/theme.css';

button.base-button {
    box-sizing: border-box;
    padding: var(--lay-pad-compact) var(--lay-pad);
    
    font: var(--font-elem-lg);
    cursor: var(--ia-cursor-clickable);
    
    border: none;
    border-radius: var(--lay-border-radius-sm);

    transition: var(--ia-transition-instant);
}

button.primary {
    color: var(--col-fg-text);
    background: var(--col-prim-elem);
    border: var(--lay-border-slim) solid var(--col-prim-elem);
}

button.outlined {
    color: var(--col-fg-text);
    background: transparent;
    border: var(--lay-border-slim) solid var(--col-prim-elem);
}

button.primary:hover,
button.outlined:hover {
    color: var(--col-fg-text-highlight);
    background: var(--col-prim-elem-hover);
    border: var(--lay-border-slim) solid var(--col-prim-elem-hover);
}

button.primary:active,
button.outlined:active {
    background: var(--col-prim-elem-active);
    border: var(--lay-border-slim) solid var(--col-prim-elem-active);
}

button.deactivated,
button.deactivated:hover {
    cursor: default;
    color: var(--col-fg-text-inactive);
    background: var(--col-prim-elem-inactive);
    border: var(--lay-border-slim) solid var(--col-prim-elem-inactive);
}

button.outlined.deactivated,
button.outlined.deactivated:hover {
    background: transparent;
}