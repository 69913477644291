@import '../../assets/styles/theme.css';

.status-page-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
}

.status-message-container {
    align-self: center;
    max-width: 480px;
    border-radius: 20px;
    background-color: var(--color-bg-elem-dp1-glass);
    box-shadow: 2px 2px 2px var(--color-bg-elem-dp1-dk-glass);
    padding: 16px;
    margin: 32px;
}