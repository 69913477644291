@import '../../assets/styles/theme.css';

.volume-slider {
    position: relative;
    display: flex;
    width: 100%;
}

.volume-slider .volume-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.volume-slider .slider {
    -webkit-appearance: none;
    appearance: none;
    
    position: absolute;
    margin: 7px 0px;
    inset: 0;
    width: 100%;
    height: 10px;
    
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid var(--color-bg-elem-dp1-lg);
    outline: none;
    
    background-color: transparent;
    cursor: pointer;
}

.volume-slider .track-container {
    position: absolute;
    inset: 0;
    margin: 7px 1px;
    border-radius: 6px;
    overflow: hidden;
    background-color: var(--color-bg-elem-dp1-dk);
    transition: .1s;
}

.volume-slider .track {
    background-color: var(--color-primary);
    width: var(--progress);
    height: 100%;
    transition: background-color .1s;
}

.volume-slider .track.muted {
    background-color: var(--color-bg-elem-dp2);
}

.volume-slider:hover .track-container {
    background-color: var(--color-bg-elem-dp1-lg);
}

.volume-slider:hover .track {
    background-color: var(--color-primary-hover);
}

.volume-slider:hover .track.muted {
    background-color: var(--color-bg-elem-dp2-lg);
}

.volume-slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    background: var(--color-primary);
    border: none;
    border-radius: 100%;
    outline: solid 0px var(--color-primary-glass);
    transition: 0.1s;
}

.volume-slider .slider::-webkit-slider-thumb:hover {
    background: var(--color-primary-hover);
    outline: solid 8px var(--color-primary-glass);
}

.volume-slider .slider:active::-webkit-slider-thumb {
    background: var(--color-primary-hover);
    outline: solid 8px var(--color-primary-glass);
}

.volume-slider .slider.muted::-webkit-slider-thumb {
    background: var(--color-bg-elem-dp2-lg);
    outline: solid 0px var(--color-bg-elem-dp2-lg-glass);
}

.volume-slider .slider.muted::-webkit-slider-thumb:hover {
    outline: solid 8px var(--color-bg-elem-dp2-lg-glass);
}

.volume-slider .slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: var(--color-primary);
    border: none;
    border-radius: 100%;
    outline: solid 0px var(--color-primary-glass);
    transition: 0.1s;
}

.volume-slider .slider::-moz-range-thumb:hover {
    background: var(--color-primary-hover);
    outline: solid 8px var(--color-primary-glass);
}

.volume-slider .slider:active::-moz-range-thumb {
    background: var(--color-primary-hover);
    outline: solid 8px var(--color-primary-glass);
}

.volume-slider .slider.muted::-moz-range-thumb {
    background: var(--color-bg-elem-dp2-lg);
    outline: solid 0px var(--color-bg-elem-dp2-lg-glass);
}

.volume-slider .slider.muted::-moz-range-thumb:hover {
    outline: solid 8px var(--color-bg-elem-dp2-lg-glass);
}