@import './styles/theme.css';

body {
    margin: 0;

    background-color: var(--col-bg-dp0);
    color: var(--col-fg-text);

    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 
    'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    font: var(--font-body);
}

.app-client {
    width: 100vw;
    height: 100vh;
    min-width: var(--lay-column-width);
}

.header-container {
    position: relative;
    height: var(--lay-app-header-height);
}

.page-container {
    position: relative;
    height: calc(100% - var(--lay-app-header-height));
}
