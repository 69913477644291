@import '../../assets/styles/theme.css';

.news-container {
    margin: 12px;
    padding: 4px;
    padding-bottom: 8px;
    height: 216px;
    box-sizing: border-box;
    display: flex;
    overflow: auto;
    gap: 16px;
}