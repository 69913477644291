@import '../../assets/styles/theme.css';

.atmosphere-matrix-scroll-container {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.atmosphere-matrix-scroll-shadow-container {
    position: absolute;
    height: 100%;
    width: 100%;
}

.atmosphere-matrix {
    margin: 0px 0px;
    display: flex;
    flex-direction: column;
}

.atmosphere-matrix-header {
    display: flex;
    background-color: var(--color-bg-elem-dp2-dk-glass);
    padding: 12px 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: solid 3px var(--color-bg-elem-dp1-dk-glass);
}

.atmosphere-matrix-category {
    max-width: 272px;
    min-width: 272px;
    display: flex;
    justify-content: center;
    user-select: none;
}

.atmosphere-matrix-body {
    background: linear-gradient(var(--color-bg-elem-dp1-dk-glass), 50%, transparent);
    position: relative;
    display: flex;
    flex-grow: 1;
}

.atmosphere-matrix-element-list-anchor {
    position: relative;
    max-width: 272px;
    min-width: 272px;
    height: 100%;
    gap: 16px;
}

.atmosphere-matrix-element-list {
    position: absolute;
    height: calc(100% - 24px);
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin: 12px 0px;
    padding: 4px 12px;
    gap: 12px;
    box-sizing: border-box;
}

.atmosphere-matrix-element {
    box-sizing: border-box;
    padding: 16px 8px;
    background-color: var(--color-bg-elem-dp2-dk-glass);
    box-shadow: 0px 0px 0px 0px var(--color-primary);
    border-radius: 8px;
    transition: 0.3s;
    user-select: none;
    cursor: pointer;
    text-align: center;
    min-height: 56px;
}

.atmosphere-matrix-element:hover {
    background-color: var(--color-bg-elem-dp2);
}

.atmosphere-matrix-element:active {
    background-color: var(--color-primary);
}

.atmosphere-matrix-element.active {
    background-color: var(--color-bg-elem-dp2);
    box-shadow: 0px 0px 6px 1px var(--color-primary);
}

.atmosphere-matrix-element.variations {
    padding: 8px;
    min-height: 64px;
    flex-shrink: 0;
}

.atmosphere-matrix-element-variation {
    box-sizing: border-box;
    padding: 1px 8px;
    margin-top: 4px;
    background-color: var(--color-bg-elem-dp2-lg-glass);
    border-radius: 128px;
    font: var(--font-body-small)
}

.atmosphere-matrix-element-variation:hover {
    background-color: var(--color-bg-elem-dp2-lg);
}

.atmosphere-matrix-element-variation:active {
    background-color: var(--color-bg-elem-dp2);
}

.atmosphere-matrix-element-variation.active {
    background-color: var(--color-primary);
    box-shadow: 0px 0px 6px 1px var(--color-primary);
}