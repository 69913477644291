@import '../../../styles/theme.css';

.login-page-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form-background {
    display: flex;
    flex-direction: column;
    width: var(--lay-column-width);
    gap: var(--lay-gap-v-separate);
    border-radius: 20px;
    padding: var(--lay-pad);
}

.login-form {
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--lay-gap-v-connect);
}

.login-reset-link{
    display: block;
    color: var(--col-fg-text-hint);
    font: var(--font-elem-sm);
    text-align: end;
}

.login-form-help{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--lay-gap-h-connect);
    flex-wrap: wrap;
}

.login-form-separator{
    width: 100%; 
    height: 1px;
    background-color: var(--col-elem-socket-border);
    margin-top: 8px;
}

.login-button-loading{
    display: flex;
    flex-direction: column;
    align-items: center;
}