@import url('./common.css');
@import url('./fonts.css');

@import url('../../../styles/colors.css');
@import url('../../../styles/fonts.css');

:root{
    --size-header-height: 48px;
    --size-page-height: calc(100vh - var(--size-header-height));
    --size-page-width: 100vw;

    --color-primary: hsl(170, 100%, 34%);
    --color-primary-glass: hsla(170, 100%, 34%, 70%);
    --color-primary-hover: hsl(168, 100%, 40%);
    --color-primary-active: hsl(166, 100%, 46%);
    --color-primary-inactive: hsla(170, 100%, 34%, 20%);
    --color-on-primary: #fff;
    --color-secondary: hsl(301, 71%, 35%);
    --color-secondary-glass: hsla(301, 71%, 35%, 70%);
    --color-secondary-hover: hsl(301, 71%, 40%);
    --color-secondary-active: hsl(301, 71%, 30%);
    --color-on-secondary: #fff;

    --color-warning: gold;
    --color-error: red;
    --color-danger: #E11;
    --color-danger-lg: #F22;
    --color-danger-dk: #B00;
    --color-danger-inactive: #B009;

    --color-background: hsl(245, 18%, 9%);
    --color-background-glass: hsla(245, 18%, 9%, 70%);
    --color-foreground: var(--col-fg-text);
    --color-foreground-hinted: hsl(0, 0%, 80%);
    --color-foreground-inactive: hsl(0, 0%, 60%);
    --color-foreground-emph: hsl(170, 100%, 80%);

    --color-custom-scrollbar: hsl(245, 20%, 80%);

    --color-bg-elem-dp1: hsl(245, 16%, 13%);
    --color-bg-elem-dp1-glass: hsla(245, 16%, 13%, 70%);
    --color-bg-elem-dp1-lg: hsl(245, 16%, 15%);
    --color-bg-elem-dp1-lg-glass: hsla(245, 16%, 15%, 70%);
    --color-bg-elem-dp1-dk: hsl(245, 19%, 11%);
    --color-bg-elem-dp1-dk-glass: hsla(245, 19%, 11%, 70%);
    --color-bg-elem-dp1-dk-glass80: hsla(245, 19%, 11%, 80%);

    --color-bg-elem-dp2: hsl(245, 13%, 17%);
    --color-bg-elem-dp2-glass: hsla(245, 13%, 17%, 70%);
    --color-bg-elem-dp2-lg: hsl(245, 13%, 24%);
    --color-bg-elem-dp2-lg-glass: hsla(245, 13%, 24%, 70%);
    --color-bg-elem-dp2-dk: hsl(245, 13%, 14%);
    --color-bg-elem-dp2-dk-glass: hsla(245, 13%, 14%, 70%);


    /*
    --font-h1-headline: 30px/42px DellaRespira;
    --font-h2-headline: 24px/34px DellaRespira;
    --font-h3-headline: 600 20px/28px Montserrat;
    --font-h4-headline: 600 16px/28px Montserrat;
    --font-body-large: 18px/30px Montserrat;
    --font-body: 16px/24px Montserrat;
    --font-body-medium: 14px/20px Montserrat;
    --font-body-small: 12px/18px Montserrat;
    --font-body-tiny: 10px/12px Montserrat;
    */
    --font-h1-headline: var(--font-head-h1);
    --font-h2-headline: var(--font-head-h2);
    --font-h3-headline: var(--font-head-h3);
    --font-h4-headline: var(--font-head-h4);
    --font-body-large: var(--font-elem-lg);
    --font-body: var(--font-body);
    --font-body-medium: var(--font-elem-md);
    --font-body-small: var(--font-body-sm);
    --font-body-tiny: var(--font-elem-tn);
}

h1 {
    margin: 0;
    font: var(--font-h1-headline)
}

h2 {
    margin: 0;
    font: var(--font-h2-headline)
}

h3 {
    margin: 0;
    font: var(--font-h3-headline)
}

h4 {
    margin: 0;
    font: var(--font-h4-headline)
}

a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-weight: bold;
    color: var(--color-foreground);
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}