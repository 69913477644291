@import '../../../styles/theme.css';

.brand-logo{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
}

.brand-logo-tag {
    font: var(--font-elem-sm);
    position: absolute;
    right: -12px;
    top: -8px;
    padding: 0px var(--lay-pad-compact);
    border-radius: 128px;
    background-color: var(--col-prim-glass);
}

.brand-logo-text{
    font-family: DellaRespira;
    width: max-content;
}