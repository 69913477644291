@import '../../../assets/styles/theme.css';

.mobile-disclaimer-page-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mobile-disclaimer {
    margin: 16px;
    padding: 16px;
    border-radius: 16px;
    background-color: var(--color-bg-elem-dp1-dk-glass);
}

.mobile-disclaimer p {
    margin: 0px;
}