:root {
    /* Height of the application header */
    --lay-app-header-height: 48px;

    /* For distancing nodes within a shared context (element, form, etc) */
    --lay-gap-h-connect: 8px;
    --lay-gap-v-connect: 8px;
    --lay-gap-h-separate: 16px;
    --lay-gap-v-separate: 16px;

    /* For separating elements in the layout */
    --lay-space-h: 24px;
    --lay-space-h-short: 16px;
    --lay-space-h-wide: 32px;
    --lay-space-v: 32px;
    --lay-space-v-short: 24px;
    --lay-space-v-wide: 40px;

    /* Element paddings */
    --lay-pad: 16px;
    --lay-pad-slim: 4px;
    --lay-pad-compact: 8px;
    --lay-pad-wide: 24px;

    /* Border radius used on elements */
    --lay-border-radius-sm: 8px;
    --lay-border-radius-tn: 4px;
    --lay-border-radius: 24px;

    /* Border sizes for elements */
    --lay-border-slim: 1.5px;
    --lay-border-thick: 2.5px;

    /* Default box outlines and shadow layouts */
    --lay-box-shadow: 2px 2px 2px;
    --lay-outline-glow: 0px 0px 6px 1px;

    /* Horizontal base unit for layouts */
    --lay-column-width: 320px;
}