@import '../../assets/styles/theme.css';

input.depr-default{
    border: 1px solid var(--color-bg-elem-dp2-lg);
    border-radius: 5px;
    padding: 16px;
    font-size: 16;
    color: var(--color-foreground);
    background-color: var(--color-bg-elem-dp2-glass);

    transition: 0.25s;
}

input.depr-default:focus{
    box-shadow: 0 0 0 2px var(--color-primary) inset;
    outline: none;
}

input.depr-slim{
    border: 1px solid var(--color-bg-elem-dp2-lg);
    border-radius: 5px;
    padding: 8px;
    font-size: 16;
    color: var(--color-foreground);
    background-color: var(--color-bg-elem-dp2-glass);
    box-sizing: border-box;
    transition: 0.25s;
}

input.depr-slim:focus{
    box-shadow: 0 0 0 2px var(--color-primary) inset;
    outline: none;
}

input.depr-invalid{
    border: 1px solid var(--color-error);
}

input.depr-invalid:focus{
    border: 1px solid var(--color-error);
    box-shadow: none;
}

input.depr-invalid.warning{
    border: 1px solid var(--color-warning);
}

input.depr-invalid.warning:focus{
    border: 1px solid var(--color-warning);
}

.depr-form-input-checkbox {
    display: flex;
    flex-direction: row;
}

.depr-form-input-checkbox input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-top: 4px;
    margin-right: 8px;
    border: 1px solid var(--color-bg-elem-dp2-lg);
    background-color: var(--color-bg-elem-dp2-glass);
    outline: none;
    cursor: pointer;
}

.depr-form-input-checkbox input[type="checkbox"].invalid {
    border: 0.15em solid var(--color-error);
}

.depr-form-input-checkbox input[type="checkbox"].invalid.warning {
    border: 0.15em solid var(--color-warning);
}

.depr-form-input-checkbox input.checked {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    position: relative;
}

.depr-form-input-checkbox input.checked::before {
    content: "\2713";
    font-size: 1.3em;
    color: #fff;
    position: absolute;
    right: 2px;
    top: -3.7px;
}

.depr-form-input-validation-hint {
    width: calc(100% - 8px);
    color: var(--color-error);
    font: var(--font-body-small);
    margin-left: 8px;
}

.depr-form-input-validation-hint.warning {
    color: var(--color-warning);
}

.depr-form-input-checkbox-invalid-hint {
    width: calc(100% - 8px);
    color: var(--color-error);
    font: var(--font-body-small);
}

.depr-form-input-checkbox-invalid-hint.warning {
    color: var(--color-warning);
}