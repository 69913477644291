@import '../../styles/theme.css';

.feedback-splash-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-background-glass);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.feedback-splash-widget {
    position: relative;
    border-radius: var(--lay-border-radius);
    background-color: var(--color-bg-elem-dp2);
    min-width: 360px;
    max-width: 680px;
    max-height: 780px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.feedback-splash-widget .feedback-header{
    background-color: var(--color-bg-elem-dp1);
    padding: var(--lay-pad-wide) var(--lay-pad);
}

.feedback-splash-widget .feedback-close{
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--lay-pad-compact) var(--lay-pad-compact);
    color: var(--col-fg-icon-inactive);
    cursor: pointer;
}
.feedback-splash-widget .feedback-close:hover{
    color: var(--col-attention-elem-hover);
}

.feedback-splash-widget .feedback-body{
    display: flex;
    flex-direction: column;
    gap: var(--lay-gap-v-separate);
    padding: var(--lay-pad);
    overflow: auto;
}

.feedback-splash-widget .feedback-footer{
    padding: var(--lay-pad-wide) var(--lay-pad);
}


.feedback-rating{
    cursor: pointer;
}

.feedback-rating.selected{
    color: var(--col-prim-elem);
}

.feedback-rating:hover {
    color: var(--col-prim-elem-hover);
}


.feedback-splash-container .atmosphere-title-container {
    display: flex;
    align-items: center;
    margin: var(--lay-pad) 0;
}

.feedback-splash-container .atmosphere-title-thumb {
    border-radius: 4px;
    width: 64px;
    height: 64px;
    object-fit: cover;
}

.feedback-splash-container .atmosphere-title-info {
    display: flex;
    flex-direction: column;
    margin-left: var(--lay-space-h-short);
    gap: var(--lay-gap-v-connect);
}