@import '../../assets/styles/theme.css';

.news-card {
    min-height: 192px;
    min-width: 192px;
    max-height: 192px;
    max-width: 192px;
    border-radius: 16px;
    background-color: var(--color-bg-elem-dp1-dk);
    box-shadow: 2px 2px 2px var(--color-bg-elem-dp1-glass);
    overflow: hidden;

    display: flex;
    flex-direction: column;

    cursor: pointer;
    transition: 0.1s;
}

.news-card:hover {
    background-color: var(--color-bg-elem-dp1-lg);
}

.news-card:active {
    background-color: var(--color-primary);
}

.news-card .news-thumbnail {
    width: 192px;
    height: 144px;
    object-fit: cover;
    overflow: hidden;
}

.news-card .news-thumbnail .no-thumbnail {
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-elem-dp2-glass);
    display: flex;
    align-items: center;
    justify-content: center;
}

.news-card .news-thumbnail .no-thumbnail .icon-container {
    width: 128px;
    height: 128px;
    border-radius: 100%;
    background-color: var(--color-primary-inactive);
    color: var(--color-foreground-hinted);
    display: flex;
    align-items: center;
    justify-content: center;
}

.news-card .news-date {
    font: var(--font-body-small);
    margin-top: 4px;
    margin-left: 8px;
    color: var(--color-foreground-hinted);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-card .news-title {
    font: var(--font-body-medium);
    margin-top: -2px;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}