@import '../../../assets/styles/theme.css';

.required-action-page-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.required-actions-background {
    position: relative;
    max-height: 80%;
    min-height: 300px;
    border-radius: 20px;
    background-color: var(--color-bg-elem-dp1-glass);
    box-shadow: 2px 2px 2px var(--color-bg-elem-dp1-dk-glass);
    margin: 24px 0px;
    padding: 16px;
}

.required-actions-form {
    position: relative;
    max-height: 100%;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;  
}

.required-actions-description {
    margin: 0;
    color: var(--color-foreground-hinted);
}

.required-actions-reader {
    max-height: 100%;
    overflow: hidden scroll;
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--color-bg-elem-dp2);
}