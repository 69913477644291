@import '../../assets/styles/theme.css';

.user-icon{
    box-sizing: border-box;
    width: calc(var(--size-header-height) - 8px);
    height: calc(var(--size-header-height) - 8px);
    margin: 4px 8px;
    padding: 2px;
    border-radius: 100%;
    background-color: var(--color-bg-elem-dp2-lg);
    transition: 0.2s;
    overflow: hidden;
    cursor: pointer;
}

.user-icon:hover {
    box-shadow: 0px 0px 2px 2px var(--color-primary);
}