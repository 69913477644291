@import '../../assets/styles/theme.css';

.audio-meta-info-container {
    width: 100%;
    margin: 2px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.audio-meta-info-container a {
    position: relative;
    text-decoration: none;
    cursor: pointer;
}

.audio-meta-icon {
    width: 56px;
    height: 56px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-background);
    border: solid thin var(--color-bg-elem-dp2);
    border-radius: 8px;
    overflow: hidden;
}

.audio-meta-text a {
    color: inherit;
}

.audio-meta-text a:hover {
    text-decoration: underline;
}

/*
.audio-meta-info-container a .url-tag {
    position: absolute;
    top: 1px;
    right: 4px;
    font: var(--font-body-tiny);
    font-weight: bold;
}

.audio-meta-info-container a:hover .url-tag {
    text-decoration: underline;
}

.audio-meta-info-card {
    width: 100%;
    padding: 2px 6px;
    box-sizing: border-box;
    border-left: solid medium var(--color-primary);
    background-color: var(--color-bg-elem-dp1-lg-glass);
    font: var(--font-body-small);
    display: flex;
    flex-direction: column;
}

.audio-meta-info-container a:hover .audio-meta-info-card {
    border-left: solid medium var(--color-primary-hover);
    background-color: var(--color-bg-elem-dp2);
}

.audio-meta-info-card .info-tag {
    color: var(--color-foreground-hinted);
}

.audio-meta-info-card .info-thumbnail-container {
    width: 64px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
}

.audio-meta-info-card .info-thumbnail {
    width: 60px;
    height: 60px;
    border-radius: 3px;
}
*/