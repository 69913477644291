@import '../../../../assets/styles/theme.css';

.hub-subpage-surveys {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
}

.hub-subpage-surveys .info-box {
    display: flex;
}

.hub-subpage-surveys .info-box .info-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.hub-subpage-surveys .info-box .info-desc {
    display: flex;
    flex-direction: column;
}

.hub-subpage-surveys h3 {
    margin: 16px 0px 8px 0px;
}

.survey-list {
    margin: 0px 24px;
    display: flex;
    flex-direction: column;
}

.survey-list-item {
    background-color: var(--color-bg-elem-dp2-glass);
    margin: 8px 0px;
    padding: 8px 16px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    max-width: 480px;   
}

.survey-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.survey-list-item.submitted,
.survey-list-item.submitted:hover,
.survey-list-item.submitted:active {
    background-color: var(--color-bg-elem-dp1-glass);
    color: var(--color-foreground-inactive);
    cursor: default;
}

.survey-list-item:hover {
    background-color: var(--color-bg-elem-dp2-lg-glass);
}

.survey-list-item:active {
    background-color: var(--color-bg-elem-dp2-dk-glass);
}

.survey-submitted-check {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: var(--color-on-primary);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    flex-shrink: 0;
}

.survey-submitted-check-empty {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: solid 2px var(--color-foreground-inactive);
    flex-shrink: 0;
}