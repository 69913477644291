@import '../../assets/styles/theme.css';

.depr-button{
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: 0.1s;
}

.depr-button.default{
    background-color: var(--color-primary);
    color: var(--color-on-primary);
}

.depr-button.outlined{
    box-sizing: border-box;
    border: 1px solid;
    background-color: transparent;
    color: var(--color-foreground);
    border-color: var(--color-primary);
}

.depr-button.outlined-err{
    box-sizing: border-box;
    border: 1px solid;
    background-color: transparent;
    color: var(--color-foreground);
    border-color: var(--color-error);
}

.depr-button.deactivated{
    box-sizing: border-box;
    border: 1px solid;
    color: var(--color-foreground-inactive);
    background-color: var(--color-primary-inactive);
    border-color: var(--color-primary-inactive);
}

.depr-button.outlined.deactivated {
    background-color: transparent;
    color: var(--color-foreground-inactive);
    border-color: var(--color-primary-inactive);
}

.depr-button.outlined-err.deactivated {
    background-color: transparent;
    color: var(--color-foreground-inactive);
    border-color: var(--color-danger-inactive);
}

.depr-button.default:hover{
    background-color: var(--color-primary-hover);
}

.depr-button.default:active{
    background-color: var(--color-primary-active);
}

.depr-button.outlined:hover{
    background-color: var(--color-primary-hover);
}

.depr-button.outlined-err:hover{
    background-color: var(--color-danger);
}

.depr-button.outlined.deactivated:hover,
.depr-button.outlined-err.deactivated:hover {
    background-color: transparent;
}

.depr-button.round{
    padding: 0;
    height: 30px;
    width: 30px;
    border-radius: 25px;
    text-align: center;
    border: 1px solid white;
}

.depr-button.round:hover{
    border: 1px solid var(--color-primary);
}

.icon{
    margin: 6px auto;
    width: 18px;
    height: 18px;
}
