@import '../../../styles/theme.css';

.textbox {
    /* remove default appearence */
    -webkit-appearance: none;
    appearance: none;
    /* create a custom design */
    position: relative;
    box-sizing: border-box;
    padding: var(--lay-pad-compact) var(--lay-pad);
    width: 100%;
    font: var(--font-elem-md);
    color: var(--col-fg-text);
    background-color: var(--col-elem-bg-socket);

    border: var(--lay-border-slim) solid var(--col-elem-socket-border);
    border-radius: var(--lay-border-radius-sm);

    transition: var(--ia-transition-instant);

    max-width: 100%; 
    max-height: 100%;
}

.textbox.wide{
    padding: var(--lay-pad) var(--lay-pad);
}

.textbox-leading-icon{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: calc(var(--lay-pad-compact) + var(--lay-border-slim));
    height: 20px;
    z-index: 1;
}

.textbox-leading-icon > svg{
    height: 100%;
    width: 100%;
}

.textbox-leading-icon.deactivated > svg{
    height: 100%;
    width: 100%;
    color: var(--col-fg-text-inactive);
}

.textbox-leading-icon > img{
    height: 100%;
    width: 100%;
}

.textbox.show-icon{
    padding-left: calc(2*var(--lay-pad-compact) + 20px);
}

.textbox:focus{
    outline: none;
    border: var(--lay-border-slim) solid var(--col-prim);
}

.textbox.deactivated{
    color: var(--col-fg-text-inactive);
    border-color: var(--col-elem-socket-border-inactive);
    background-color: var(--col-elem-bg-socket-inactive);
}

.textbox-label.deactivated{
    color: var(--col-fg-text-inactive);
}

.textbox.invalid,
.textbox.invalid:focus {
    border: var(--lay-border-slim) solid var(--col-attention);
}

.textbox-label {
    font: var(--font-body);
    user-select: none;
    line-height: 1.8;
}

.textbox-label.invalid {
    color: var(--col-attention);
}

.textbox-invalid-label {
    font: var(--font-body-sm);
    line-height: 1.8;
    color: var(--col-attention);
    user-select: none;
}