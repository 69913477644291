@import '../../assets/styles/theme.css';

.survey {
    position: absolute;
    max-width: 720px;
    inset: 8px;
    padding: 16px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    overflow: auto;
}

.survey-body {
    position: relative;
    flex-grow: 1;
}