@font-face {
    font-family: 'DellaRespira';
    src: url(../assets/fonts/della-respira/della-respira.regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: url(../assets/fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
}

body {
    font-size: 100%;
}

:root {
    --lh-mult-head: 1.4;
    --lh-mult-elem: 1.2;
    --lh-mult-body: 1.3;

    --fs-head-h1: 1.9rem;
    --fs-head-h2: 1.6rem;
    --fs-head-h3: 1.3rem;
    --fs-head-h4: 1.1rem;

    --fs-elem-lg: 1.1rem;
    --fs-elem-md: 1rem;
    --fs-elem-sm: 0.85rem;
    --fs-elem-tn: 0.7rem;

    --fs-body: 1rem;
    --fs-body-sm: 0.85rem;

    /* Font System
     * The system differentiates between 3 type systems: headline, body and element.
     * Headline fonts are used to provide structure to a page layout.
     * Body fonts are used in continous text.
     * Element fonts are used to convey information inside UX elements.
     */    
    --font-head-h1:     var(--fs-head-h1)/var(--lh-mult-head) DellaRespira;
    --font-head-h2:     var(--fs-head-h2)/var(--lh-mult-head) DellaRespira;
    --font-head-h3: 600 var(--fs-head-h3)/var(--lh-mult-head) Montserrat;
    --font-head-h4: 600 var(--fs-head-h4)/var(--lh-mult-head) Montserrat;

    --font-elem-lg: var(--fs-elem-lg)/var(--lh-mult-elem) Montserrat;  /* Big elements (buttons, etc) */
    --font-elem-md: var(--fs-elem-md)/var(--lh-mult-elem) Montserrat;  /* Default reference size */
    --font-elem-sm: var(--fs-elem-sm)/var(--lh-mult-elem) Montserrat;
    --font-elem-tn: var(--fs-elem-tn)/var(--lh-mult-elem) Montserrat;

    --font-body:    var(--fs-body)/var(--lh-mult-body) Montserrat;
    --font-body-sm: var(--fs-body-sm)/var(--lh-mult-body) Montserrat;
}