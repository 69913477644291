@import '../../../styles/theme.css';

.animated-background-container {
    position: absolute;
    inset: 0;
    overflow: hidden;
}

.animated-background-shadow {
    position: absolute;
    inset: 0;
    box-shadow: inset 0px 0px 128px 128px var(--col-bg-dp0);
}

.animated-background-image {
    user-select: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@keyframes animated-background-image-anim {
    0% { 
        margin-left: 0%;
        width: 100%;
    }
    100% { 
        margin-left: -5%;
        width: 110%;
    }
}

@media (orientation: landscape) {
    .animated-background-image {
        animation: 10s cubic-bezier(0.2, 0, 0.8, 1.0) alternate infinite animated-background-image-anim;
    }
}

@media (orientation: portrait) {
    .animated-background-image {
        animation: none;
    }
}

@media (max-width: 720px) or (max-height: 640px) {
    .animated-background-shadow {
        box-shadow: 0px 0px 256px 128px var(--col-bg-dp0) inset;
    }
}

@media (max-width: 480px) or (max-height: 360px) {
    .animated-background-shadow {
        box-shadow: 0px 0px 128px 64px var(--col-bg-dp0) inset;
    }
}

@media (max-width: 320px) or (max-height: 360px) {
    .animated-background-container {
        display: none;
    }

    .animated-background-shadow {
        display: none;
    }

    .animated-background-image {
        display: none;
    }
}