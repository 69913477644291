@import "../../assets/styles/theme.css";

.dropdown,
.dropdown-click {
    position: relative;
    display: inline-block;
}

.dropdown-menu{
    display: none;
    position: absolute;
    z-index: 20;

    padding: 0px;
    border-radius: 4px;

    background-color: var(--color-bg-elem-dp2);
    border: solid 2px transparent;
}

.dropdown.open .dropdown-menu {
    display: flex;
    flex-direction: column;
    border: solid 2px var(--color-bg-elem-dp2-lg);
}

.dropdown:hover .dropdown-menu,
.dropdown:focus .dropdown-menu,
.dropdown-click.open .dropdown-menu {
    display: flex;
    flex-direction: column;
}

.dropdown .cover-anchor-component,
.dropdown-click .cover-anchor-component {
    width: 100%;
}

.dropdown .right-aligned,
.dropdown-click .right-aligned {
    width: max-content;
    right: 0;
}

.dropdown-element{
    font: var(--font-body);
    color: var(--color-foreground);
    
    width: 100%;
    padding: 2px 12px 2px 4px;
    position: relative;
    box-sizing: border-box;
    border: none;
    
    display: flex;
    flex-direction: row;
    text-align: left;

    background-color: transparent;
    cursor: pointer;
    transition: 0.1s;
}

.dropdown-element:hover{
    background-color: var(--color-bg-elem-dp2-lg);
}

.dropdown-element:active{
    background-color: var(--color-bg-elem-dp2-dk);
}

.dropdown-element-separator {
    width: calc(100% - 32px);
    height: 1px;
    margin: 0px 16px;
    background: var(--color-bg-elem-dp2-lg-glass);
}

.dropdown-icon{
    display: flex;
    flex-direction: column;
    margin: 0px 2px;
}

.dropdown-box {
    width: 100%;
    padding: 4px;
    background: var(--color-bg-elem-dp2-dk);
    border-radius: 4px;
    border-bottom: solid thin transparent;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: 0.1s;
}

.dropdown-box:hover {
    background: var(--color-bg-elem-dp2);
    border-bottom: solid thin var(--color-bg-elem-dp2-dk);
}