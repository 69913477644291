@import '../../assets/styles/theme.css';

.header-tab-container {
    margin: 0px 8px;
    margin-top: 4px;
    height: 42px;
    display: flex;
}

.header-tab {
    position: relative;
    box-sizing: border-box;
    width: 144px;
    cursor: pointer;
    border-bottom: solid 2px var(--color-bg-elem-dp2);    
    user-select: none;
    display: flex;
}

.header-tab-info-container {
    position: relative;
    height: 100%;
    flex-grow: 1;
}

.header-tab-info {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
}

.header-tab-thumb-container {
    position: relative;
    padding-left: 8px;
    width: 32px;
    height: 40px;
}

.header-tab-thumbnail {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin: 4px 0px;
    object-fit: cover;
    border-radius: 100%;
}

.header-tab:hover {
    border-bottom: solid 2px var(--color-bg-elem-dp2-lg);
}

.header-tab:active {
    background-color: var(--color-bg-elem-dp2-dk);
}

.header-tab.active {
    width: 224px;
}

.header-tab.active .header-tab-info-container,
.header-tab.active .header-tab-thumb-container {
    background-color: var(--color-bg-elem-dp2);
}

.header-tab.active .header-tab-thumb-container {
    padding-left: 0px;
}

.header-tab-head {
    font: var(--font-body-tiny);
    color: var(--color-foreground-hinted);
}

.header-tab-title {
    font: var(--font-body-small);
    color: var(--color-foreground);
    padding-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-edge {
    height: 40px;
    width: 26px;
}

.header-tab-player-control {
    position: absolute;
    background-color: #0005;
    color: var(--color-foreground-hinted);
    top: 4px;
    left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.header-tab.active .header-tab-player-control {
    left: 0px;
}

.header-tab-player-control:hover {
    background-color: #0007;
    color: var(--color-foreground);
}

.header-tab-player-control:active {
    background-color: #0009;
    color: var(--color-foreground);
}