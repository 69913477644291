@import '../../../../assets/styles/theme.css';

.hub-subpage-home {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
}

.hub-subpage-home .info-box {
    display: flex;
    color: var(--color-foreground-hinted);
    font: var(--font-body-medium);
}

.hub-subpage-home .info-box .info-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.hub-subpage-home .info-box .info-desc {
    display: flex;
    flex-direction: column;
}

.hub-subpage-home .atm-container {
    margin: 12px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.hub-subpage-home h3 {
    margin-top: 16px;
}

