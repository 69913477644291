@import '../../../styles/theme.css';

.overlay {
    position: relative;
    display: inline-flex;
    height: 100%;
    overflow: visible;
}

.overlay-widget{
    position: absolute;
    z-index: 50;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--color-bg-elem-dp2-dk);
    box-shadow: 2px 2px 2px var(--color-bg-elem-dp1-dk);
    width: max-content;
    max-width: 256px;

    transition: 0.3s;
    visibility: hidden;
    opacity: 0%;
}

.overlay-widget.shown{
    visibility: visible;
    opacity: 100%;
}