.custom-scrollbar,
.custom-scrollbar-horizontal {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal::-webkit-scrollbar-thumb  {
    background-color: transparent;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.01);
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar-horizontal::-webkit-scrollbar {
    height: 8px;
}

.custom-scrollbar:hover,
.custom-scrollbar:focus,
.custom-scrollbar:focus-within,
.custom-scrollbar-horizontal:hover,
.custom-scrollbar-horizontal:focus,
.custom-scrollbar-horizontal:focus-within {
    scrollbar-color: var(--color-custom-scrollbar) rgba(0,0,0,0.25);
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:focus::-webkit-scrollbar-thumb,
.custom-scrollbar:focus-within::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal:hover::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal:focus::-webkit-scrollbar-thumb,
.custom-scrollbar-horizontal:focus-within::-webkit-scrollbar-thumb {
    background-color: var(--color-custom-scrollbar);
}

.custom-scrollbar-always,
.custom-scrollbar-always-horizontal {
    scrollbar-width: thin;
    scrollbar-color: var(--color-custom-scrollbar) rgba(0,0,0,0.25);
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
}

.custom-scrollbar-always::-webkit-scrollbar-thumb,
.custom-scrollbar-always-horizontal::-webkit-scrollbar-thumb  {
    background-color: var(--color-custom-scrollbar);
}

.custom-scrollbar-always::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar-always-horizontal::-webkit-scrollbar {
    height: 8px;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}