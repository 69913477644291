@import '../../assets/styles/theme.css';

.nav-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
}

.navbar-button {
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid var(--color-bg-elem-dp2-glass);
    font: var(--font-body);
    padding: 16px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    color: var(--color-foreground);
    transition: 0.1s;
    display: flex;
    flex-direction: row;
}

.navbar-button.clicked {
    background-color: var(--color-bg-elem-dp1);
}

.navbar-button:hover,
.navbar-button.clicked:hover {
    background-color: var(--color-bg-elem-dp1-lg);
}

.navbar-button:active,
.navbar-button.clicked:active {
    background-color: var(--color-primary);
}

.navbar-area {
    width: 348px;
    height: 100%;
    flex-shrink: 0;
    transition: 0.5s;
}

.navbar-area.retracted {
    width: 48px;
}

.navbar {
    position: absolute;
    width: 348px;
    height: 100%;
    left: 0px;
    background-color: var(--color-bg-elem-dp1-dk-glass);
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-top: solid thin var(--color-bg-elem-dp2-glass);
}

.navbar.retracted {
    left: -348px;
}

.nav-container-content {
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    box-sizing: border-box;
    overflow: auto;
}

@media (max-width: 640px) {
    .navbar-area {
        width: 100%;
    }
    
    .navbar {
        width: 100%;
    }
    
    .navbar.retracted {
        left: -100%;
    }
    
    .nav-container-content.non-exclusive{
        display: none;
    }
}

.navbar-content {
    width: calc(100% - 48px);
    margin-left: 48px;
    display: flex;
    flex-direction: column;
}

.navbar-retract-button {
    position: absolute;
    margin: 8px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: var(--color-bg-elem-dp1-dk);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    cursor: pointer;
}

.navbar-retract-button:hover {
    background-color: var(--color-bg-elem-dp2);
}

.navbar-retract-button:active {
    background-color: var(--color-primary);
}